import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="confirm-outside-navigation"
export default class extends Controller {
  confirmationMessage = "Leave the page?\nPay attention that the already provided data will not be saved."

  initialize() {
    this.confirmClickOutside = this.confirmClickOutside.bind(this);
  }

  connect() {
    document.addEventListener('click', this.confirmClickOutside, true);
  }

  disconnect() {
    document.removeEventListener('click', this.confirmClickOutside, true);
  }

  confirmClickOutside(event) {
    const target = event.target;

    if(this.element.contains(target)) return;
    // Check if the target or its ancestors are a link or a form submission button
    const isLink = target.closest("a") !== null;
    const isFormSubmitButton = target.closest("button[type='submit']") !== null;

    if (isLink || isFormSubmitButton) {
      // Navigation or form submission is triggered
      console.log("Navigation or form submission detected");
      const confirmed = confirm(this.confirmationMessage)

      if (!confirmed) {
        event.preventDefault();
      }
    }
  }
}
