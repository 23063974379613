import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["frame", "note"]

  initialize() {
    this.setActiveNote = this.setActiveNote.bind(this)
  }

  connect() {
    this.frameTarget.addEventListener('turbo:before-frame-render', this.setActiveNote)
  }

  setActiveNote(event) {
    this.noteTargets.forEach(linkItem => {
      linkItem.classList.remove('active');

      if (linkItem.href === event.target.src) {
        linkItem.classList.add('active');
      }
    })
  }
}
