import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "arrayInput"];
  static values = { arrayInputName: String }

  initialize() {
    this.confirmClickOutside = this.confirmClickOutside.bind(this);
  }

  connect() {
    this.localStorageKey = window.location;
    this.setFormData();
    document.addEventListener('click', this.confirmClickOutside, true);
    window.addEventListener("beforeunload", this.confirmPageOnload);
  }

  disconnect() {
    document.removeEventListener('click', this.confirmClickOutside, true);
    window.removeEventListener("beforeunload", this.confirmPageOnload);
  }

  confirmClickOutside(event) {
    const target = event.target;
    
    if (this.element.contains(target)) return;
    const isLink = target.closest("a") !== null;
    const isFormSubmitButton = target.closest("button[type='submit']") !== null;
    const isAllowUnsaved = target.closest("[data-prefill-allowed='true']") !== null;
    
    if ((isLink || isFormSubmitButton) && isAllowUnsaved) {
      this.saveToLocalStorage()
    } else {
      localStorage.clear()
    }
  }

  clearLocalStorage() {
    if (localStorage.getItem(this.localStorageKey) != null) {
      localStorage.removeItem(this.localStorageKey);
    }
  }

  getFormData() {
    const form = new FormData(this.formTarget);
    let data = [];

    for (var pair of form.entries()) {
      if (pair[0] != "authenticity_token") {
        data.push([pair[0], pair[1]]);
      }
    }
    
    if (this.hasArrayInputTarget) {
      for (const target of this.arrayInputTargets) {
        let inputName = target.getAttribute('data-prefill-name')
        let inputs = document.getElementsByName(inputName)
        let values = []
        for (const input of inputs) {
          values.push(input.value)
        }

        data.push([inputName, JSON.stringify(values)])
      }
    }

    return Object.fromEntries(data);
  }

  saveToLocalStorage() {
    const data = this.getFormData();
    localStorage.setItem(this.localStorageKey, JSON.stringify(data));
  }

  setFormData() {
    if (localStorage.getItem(this.localStorageKey) != null) {
      const data = JSON.parse(localStorage.getItem(this.localStorageKey));
      const form = this.formTarget.cloneNode(true);

      Object.entries(data).forEach((entry) => {
        let name = entry[0];
        let value = entry[1];
        let arrayInput = form.querySelector(`[data-prefill-name='${name}']`)

        if (this.hasArrayInputTarget && arrayInput) {
          const values = JSON.parse(value)
          for (var index in values) {
            this.hiddenInput = document.createElement('input');
            this.hiddenInput.setAttribute("type", "hidden");
            this.hiddenInput.setAttribute("name", name);
            this.hiddenInput.setAttribute("value", values[index]);
            arrayInput.appendChild(this.hiddenInput);
          }
        } else {
          let input = form.querySelector(`[name='${name}']`);
          input && (input.setAttribute("value", value));
        }
      });
      this.formTarget.innerHTML = form.innerHTML
    }
  }
}
