import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    preventConfirmation: String,
  }

  confirmationMessage = "Leave the page?\nPay attention that the already provided data will not be saved."

  initialize() {
    this.confirmOutsideNavigation = this.confirmOutsideNavigation.bind(this);
    this.confirmPageOnload = this.confirmPageOnload.bind(this);
  }

  connect() {
    this.initialFormJSON = this.getFormJSON();

    document.addEventListener("click", this.confirmOutsideNavigation, true);
    window.addEventListener("beforeunload", this.confirmPageOnload);
  }

  confirmOutsideNavigation(event) {
    const target = event.target;
  
    const isFormUnchanged = (this.initialFormJSON === this.getFormJSON() && !(this.element.getElementsByClassName('is-invalid').length > 0))
    if (this.element.contains(target) || isFormUnchanged || this.hasPreventConfirmationValue) return;
    const isLink = target.closest("a") !== null;
    const isFormSubmitButton = target.closest("button[type='submit']") !== null;
    const isAllowNav = target.closest("[data-unsaved-form-allowed='true']") !== null;

    if ((isLink || isFormSubmitButton) && !isAllowNav) {
      console.log("Navigation or form submission detected");
      this.confirmed = confirm(this.confirmationMessage)

      if (!this.confirmed) {
        event.preventDefault();
      }
    }
  }

  confirmPageOnload(event) {
    if (this.initialFormJSON !== this.getFormJSON() || (this.element.getElementsByClassName('is-invalid').length > 0)) {
      event.returnValue = this.confirmationMessage // without it confirmation will not work
    }
  }

  getFormJSON() {
    const form = new FormData(this.element);
    const formObject = Object.fromEntries(form.entries());
    return JSON.stringify(formObject);
  }

  disconnect() {
    document.removeEventListener("click", this.confirmOutsideNavigation, true);
    window.removeEventListener("beforeunload", this.confirmPageOnload)
  }
}
