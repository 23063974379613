import { Controller } from "@hotwired/stimulus"
import Modal from "bootstrap/js/dist/modal"

export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element)
  }

  close(event) {
    if (event.detail.success) {
      this.modal.dispose()
    }
  }

  handleSuccess({ detail: { success } }) {
    if (success) {
      this.modal.hide()
    }
  }
}
