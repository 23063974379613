import {Controller} from "@hotwired/stimulus"
import IMask from "imask";

export default class extends Controller {
  static values = {
    mask: String
  }

  connect() {
    this.mask = IMask(this.element, {
      mask: this.maskValue
    });
  }

  disconnect() {
    this.mask.destroy();
  }
}
