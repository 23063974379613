import {Controller} from "@hotwired/stimulus"
import IMask from "imask";

export default class extends Controller {
  static values = {
    mask: String
  }

  connect() {
    this.mask = IMask(this.element, {
      mask: '$num',
      blocks: {
        num: {
          mask: Number,
          signed: true,
          radix: '.',
          thousandsSeparator: ','
        }
      }
    });
  }

  disconnect() {
    this.mask.destroy();
  }
}
