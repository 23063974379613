const tab = () => {
  const tabElement = document.querySelector(".tab");
  if (tabElement) {
    const currentTab = document.querySelector(".tab-item.current");
    const currentStep = parseInt(currentTab.getAttribute("data-tab"));

    const tabWidth = currentTab.offsetWidth;
    tabElement.scrollLeft = (currentStep - 2) * tabWidth;
  }
};

const activeTab = () => {
  const tabItems = document.getElementsByClassName("tab-item");

  let lastActiveTab = null;
  for (var i = 0; i < tabItems.length; i++) {
    if (tabItems[i].classList.contains("active")) {
      lastActiveTab = tabItems[i];
    }
  }

  if (lastActiveTab) {
    lastActiveTab.classList.add("last-active");
  }
};

document.addEventListener("turbo:load", function () {
  tab();
  activeTab();
});
