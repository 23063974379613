import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    inputListSelector: String,
    hiddenInputName: String
  }

  initialize() {
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
  }

  connect() {
    this.initialState = this.getHiddenInputList().some(input => input.value === this.element.value);
    this.element.checked = this.initialState;

    this.element.closest('form').addEventListener('reset', () => {
      this.element.checked = this.initialState;
      this.toggleCheckbox();
    });

    this.element.addEventListener('change', this.toggleCheckbox);
  }

  toggleCheckbox(e) {
    const relInputs = this.getHiddenInputList().filter(el => el.value === this.element.value);

    if(this.element.checked ) {
      if(relInputs.length) return;
      this.hiddenInput = document.createElement('input');
      this.hiddenInput.setAttribute("type", "hidden");
      this.hiddenInput.setAttribute("name", this.hiddenInputNameValue);
      this.hiddenInput.setAttribute("value", this.element.value);
      document.querySelector(this.inputListSelectorValue).append(this.hiddenInput);
    } else {
      relInputs.forEach(input => input.remove());
    }
  }

  getHiddenInputList() {
    return [...document.querySelectorAll(this.inputListSelectorValue + ' > input[type=\'hidden\']')]
  }
}
