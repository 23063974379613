const hamburger = () => {
  const bodyWrapper = document.querySelector("body");
  const hamburgerMenu = document.querySelector("#hamburgerSidebar");
  const btnOpen = document.querySelector("#hamburgerOpen");
  const btnClose = document.querySelector("#hamburgerClose");

  if (!bodyWrapper || !hamburgerMenu || !btnOpen || !btnClose) {
    console.log("One or more elements not found. Check your IDs.");
    return;
  }

  btnOpen.addEventListener("click", () => {
    toggleHamburger();
  });

  btnClose.addEventListener("click", () => {
    toggleHamburger();
  });

  const toggleHamburger = () => {
    hamburgerMenu.classList.toggle("open");
    bodyWrapper.classList.toggle("no-scroll");
  };
};

document.addEventListener("turbo:load", function () {
  hamburger();
});
